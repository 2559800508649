@import 'app.scss';
.list {
  display: grid;
  gap: spacing(2);

  @include from-breakpoint(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: spacing(4);
  }
}

.card {
  border-radius: var(--border-radius-extra-large);
  box-shadow: var(--shadow-tile);
  background-color: var(--color-bg-default);
  overflow: hidden;

  &:nth-child(even) {
    .imageContainer {
      @include from-breakpoint(md) {
        order: 1;
      }

      @include from-breakpoint(lg) {
        order: initial;
      }
    }
  }
}

.article {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include from-breakpoint(md) {
    flex-direction: row;
  }

  @include from-breakpoint(lg) {
    flex-direction: column;
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 1.4;

  @include from-breakpoint(md) {
    flex-shrink: 0;
    width: 50%;
  }

  @include from-breakpoint(lg) {
    aspect-ratio: 1.34;
    flex-shrink: initial;
    width: initial;
  }
}

.content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  padding: spacing(2) spacing(3) spacing(3);

  @include from-breakpoint(lg) {
    padding: spacing(4) spacing(5) spacing(5);
  }
}

.button {
  margin-top: auto;
}
