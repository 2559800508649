@import 'app.scss';
@keyframes animate-image {
  0% {
    clip-path: path(
      'M346.352 35.1047C176.697 57.7897 -6.8582 57.2248 0.19718 290.471C4.34892 427.724 198.807 564.888 338.631 576.81C418.577 584.273 518.92 556.791 581.696 490.103C645.12 423.415 683.618 336.323 686.512 245.985C689.73 155.324 679.006 85.4735 593.901 26.4348C508.795 -32.6039 428.559 24.1126 346.352 35.1047Z'
    );
  }

  50% {
    clip-path: path(
      'M234.8 64.8572C65.1454 87.5422 27.745 60.111 34.8004 293.357C38.9521 430.61 132.476 521.935 272.3 533.857C352.246 541.32 491.024 519.045 553.8 452.357C617.224 385.669 647.406 367.695 650.3 277.357C653.518 186.696 638.905 123.896 553.8 64.8572C468.694 5.81853 317.007 53.8651 234.8 64.8572Z'
    );
  }

  100% {
    clip-path: path(
      'M199.622 16.0984C29.9673 38.7834 -6.23591 76.3232 0.819474 309.569C4.97121 446.822 59.7983 544.078 199.622 556C279.568 563.463 511.846 560.786 574.622 494.098C638.046 427.41 684.24 355.421 687.134 265.083C690.352 174.422 609.727 156.137 524.622 97.0984C439.516 38.0597 281.829 5.10633 199.622 16.0984Z'
    );
  }
}

.figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.clipPathSvg {
  position: absolute;
  width: 0;
  height: 0;
}

.image {
  clip-path: url('#clip-path');

  @include from-breakpoint(lg) {
    animation: animate-image 15s linear infinite alternate;
  }
}
