@import 'app.scss';
/**
 * ======================================
 * Block
 * ======================================
 */

.card {
  border-radius: var(--border-radius-extra-large);
  background: var(--color-white);
  padding: spacing(3);
  color: var(--color-black);

  @include from-breakpoint(lg) {
    padding: 2.5rem;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.tag {
  padding: 0.4125rem 0.75rem;
  font-size: 0.875rem;

  @include from-breakpoint(lg) {
    padding: 0.25rem 0.75rem;
    font-size: 1.125rem;
  }
}

.title {
  @include typography-heading-2;

  margin-top: spacing(2);
  margin-bottom: spacing(4);

  @include from-breakpoint(lg) {
    margin-top: spacing(4);
    margin-bottom: spacing(6);
  }
}

.navigationContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
  align-items: flex-start;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
