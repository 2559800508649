@import 'app.scss';
/**
 * Matchmaker
 */

/**
 * ======================================
 * Elements
 * ======================================
 */
.matchmaker {
  display: flex;
  align-items: stretch;
  background-color: var(--color-bg-primary-brand);
  width: 100%;
  min-height: 100vh;
}

.container {
  position: relative;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
  overflow: hidden;

  @include from-breakpoint(lg) {
    overflow: visible;
  }
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  grid-column: 1 / -1;
  align-items: flex-start;
  z-index: 3;
  color: var(--color-fg-on-primary-brand);

  @include from-breakpoint(lg) {
    grid-column: 1 / span 6;
  }
}

.imageContainer {
  position: relative;
  grid-column: 1 / -1;
  z-index: 2;
  margin-top: spacing(-6);
  margin-left: spacing(-3);
  width: calc(100vw + 1rem);
  aspect-ratio: 1.19;

  @include from-breakpoint(md) {
    transform: translateX(100%);
    margin-top: spacing(-20);
    width: 50%;
  }

  @include from-breakpoint(lg) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    z-index: 0;
    margin-top: 0;
    margin-left: 0;
    width: 42.9375rem;
  }
}

.title {
  margin-bottom: 0;
}

.subtitle {
  order: -1;
  margin-bottom: 0;
  animation: fade-in 1s;
  line-height: 100%;
  color: var(--color-red-100);
  font-family: var(--font-family-quote);
  font-size: 3rem;
  font-weight: 400;

  @include from-breakpoint(md) {
    font-size: 3.5rem;
  }
}

.cta {
  margin-top: spacing(4);
}

.text {
  margin-top: spacing(2);
  font-weight: 500;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;

  @include from-breakpoint(lg) {
    flex-flow: row wrap;
    gap: 1rem;
  }
}

.ctaContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem 0.75rem;

  @include from-breakpoint(md) {
    gap: 1rem;
  }
}

.locationList {
  padding-left: inherit;
}

.choiceHelp {
  margin-bottom: 0;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
